<template>
  <component
    class="link"
    :is="component"
    v-bind="{ ...$attrs, ...$props }"
    v-on="$listeners"
  >
    <slot></slot>
  </component>
</template>

<script>
  export default {
    name: 'UiLink',
    props: {
      // icon: {
      //   type: String,
      //   required: false,
      // },
      to: {
        type: String,
        required: false,
      },
    },
    computed: {
      component() {
        return this.to ? 'router-link' : 'a';
      },
    },
  };
</script>

<style scoped>
.link {
  font-family: "Rubik", Helvetica, Arial;
  font-weight: 400;
  font-size: 16px;
  color: #258ffb;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}
</style>
