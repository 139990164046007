<template>
  <a-button
    type="primary"
    block
    :class="[
      'ui-button',
      {
        tr_garay: tr_garay,
      },
    ]"
    v-bind="{ ...$attrs, ...$props }"
    v-on="$listeners"
  >
    <slot v-if="!awaitingResponse"/>
    <a-spin v-else>
      <a-icon slot="indicator" type="loading" class="ui-spin" spin />
    </a-spin>
  </a-button>
</template>

<script>
  export default {
    name: 'UiButton',
    props: {
      awaitingResponse: { 
        type: Boolean,
        default: false
      },
      tr_garay: {
        type: Boolean,
        default: false,
      }
    },
  }
</script>

<style scoped>
  .ui-button {
    height: 48px;
    font-size: 18px;
  }
  .ui-button:disabled {
    color: white;
    background-color: #abd4fe;
    border-width: 0px;
  }
  .ui-spin {
    margin-left: 10px;
    font-size: 24px;
    color: white;
  }
  .tr_garay {
    color: #7D7D7D;
    background-color: white;
    border-color: white;
    text-shadow: none;
    box-shadow: none;
  }
</style>
