function fixListeners() {
  return {
    listeners() {
      const { input, change, ...listeners } = this.$listeners;
      const handleInput = (event) => {
        this.$emit('input', event.target.value);
      };
      const handleChange = (event) => {
        this.$emit('input', event.target.value);
        this.$emit('change', event.target.value);
      };
      return { input: handleInput, change: handleChange, ...listeners };
    }
  }
}

export default fixListeners;
