export default function promiseValidateFields(validateFields, silent) {
  return new Promise((resolve, reject) => {
    validateFields((valid) => {
      if (valid || silent) {
        resolve();
      } else {
        reject();
      }
    });
  });
}
