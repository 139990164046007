<template>
  <a-form-model ref="form" v-bind="{ ...$attrs, ...$props }" v-on="$listeners" class="ui-form">
    <slot></slot>
  </a-form-model>
</template>

<script>
  export default {
    name: 'UiForm',
    mounted() {
      this.$emit('form', this.$refs.form);
    }
  }
</script>

<style lang="scss">
.ui-form {
  .ant-form-item-label label {
    color: #383838;
    font-size: 16px !important;
  }

  .ant-form-item {
    margin-bottom: 0px;
    min-height: 90px;
  }

  .ant-form-item-with-help {
    // min-height: 114px;
    margin-bottom: 16px;
  }

  // .ant-form-item-control-wrapper {
  //   min-height: 83px;
  // }

  .ant-form-item-control {
    line-height: normal !important;
  }

  .ant-form-item-label {
    line-height: 24px !important;
    padding: 0px;
  }

  .ant-form-explain {
    margin-top: 2px;
  }

  .ant-input-lg {
    height: 48px;
  }
}
</style>
