<route>
  {
  "name": "register",
  "meta": {
  "auth": false
  }
  }
</route>

<template>
  <div class="flex-column flex-grow">
    <div class="wrapper">
      <ui-form
        class="form"
        :model="values"
        :rules="rules"
        :hideRequiredMark="true"
        @form="form = $event"
        @validate="handleValidate"
        @submit.prevent="handleSubmit"
      >
        <div class="title1 mb-8">{{ $t('register.start_your_road') }} <br />to Germany today!</div>
        <div class="text3 mb-32">
          {{ $t('register.create_an_account_fill_in') }}
        </div>
        <ui-form-item label="Your E-mail" help="We'll send you a confirmation code" prop="email">
          <ui-email-input
            id="email"
            placeholder="Enter email for registration"
            v-model="values.email"
            @change="handleValidate"
          />
        </ui-form-item>
        <ui-form-item label="Set your password" help="Must be at least 8 characters" prop="password">
          <ui-password-input
            id="password"
            placeholder="Enter new password"
            v-model="values.password"
            @change="handleValidate"
          />
        </ui-form-item>
        <!-- <slide-up-down :active="hasReferral" :duration="500">
          <ui-form-item v-show="hasReferral" label="Referral code" prop="referral">
            <ui-text-input placeholder="type your code" v-model="values.referral" @change="handleValidate" />
          </ui-form-item>
        </slide-up-down>
        <div class="mb-24">
          <ui-link class="subtitle" @click="toggleReferral">
            {{ hasReferral ? "I don't have a referral code" : 'I have a referral code' }}
          </ui-link>
          <a-icon type="question-circle" class="link-question-icon" />
        </div> -->
      </ui-form>
    </div>
    <ui-button
      @click="handleSubmit"
      :disabled="btnDisabled || awaitingRegisterResponse"
      :awaitingResponse="awaitingRegisterResponse"
      >{{ $t('register.create_an_account') }}</ui-button
    >
    <copyright>{{ $t('register.by_creating_an_account_you') }}</copyright>
    <div class="text-center text2 footer">
      {{ $t('register.already_have_an_account') }}
      <ui-link to="/login">{{ $t('register.log_in') }}</ui-link>
    </div>
  </div>
</template>

<script>
import mapFields from '../utils/mapFields';
import promiseValidateFields from '../utils/promiseValidateFields';
import { enableAllValidationTriggers } from '../utils/enableAllValidationTriggers';
import UiEmailInput from '../components/ui/UiEmailInput';
import UiPasswordInput from '../components/ui/UiPasswordInput';
// import UiTextInput from '../components/ui/UiTextInput';
import UiButton from '../components/ui/UiButton';
import UiForm from '../components/ui/UiForm';
import UiFormItem from '../components/ui/UiFormItem';
import UiLink from '../components/ui/UiLink';
// import SlideUpDown from '../components/SlideUpDown';
import Copyright from '../components/Copyright';

export default {
  layout: 'auth',
  name: 'register',
  components: {
    Copyright,
    UiButton,
    UiPasswordInput,
    UiEmailInput,
    // UiTextInput,
    UiForm,
    UiFormItem,
    UiLink
    // SlideUpDown
  },
  data() {
    return {
      form: {},

      values: {
        email: '',
        password: '',
        referral: ''
      },

      rules: {
        email: [
          {
            // eslint-disable-next-line no-useless-escape
            pattern: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/,
            whitespace: true,
            message: 'Your email is invalid. Please try another one',
            trigger: 'blur'
          }
        ],
        password: [
          {
            min: 8,
            message: 'Must be at least 8 characters',
            trigger: 'blur'
          }
        ],
        referral: [
          {
            len: 6,
            pattern: /\w{6,}/,
            message: 'Referral code is invalid',
            trigger: 'blur'
          }
        ]
      },

      hasReferral: false,
      btnDisabled: true,
      awaitingRegisterResponse: false,
      hadValidationError: false
    };
  },
  computed: {
    ...mapFields({
      email: 'user.email'
    })
  },
  mounted() {
    this.awaitingRegisterResponse = false;
  },
  methods: {
    handleValidate() {
      this.btnDisabled = this.form.fields
        .filter((field) => field.$el.style.display !== 'none')
        .reduce((result, field) => {
          const validationState = field.validateState === 'error';
          if (!this.hadValidationError && validationState) {
            this.hadValidationError = true;
            enableAllValidationTriggers(this.rules);
          }

          return !field.fieldValue || validationState || result;
        }, false);
    },
    toggleReferral() {
      this.hasReferral = !this.hasReferral;
      this.$nextTick(() => {
        this.handleValidate();
      });
    },
    async handleSubmit() {
      const values = { ...this.values };
      if (!this.hasReferral) delete values.referral;

      try {
        this.awaitingRegisterResponse = true;
        await promiseValidateFields(this.form.validate);

        global.ym(process.env.VUE_APP_YANDEX_METRIKA_ID, 'reachGoal', 'create_account');
        this.$analytics.fbq.event('StudyflowRegister');

        // Logout for email exist flow (need to reach login)
        if (localStorage['feathers-jwt']) {
          try {
            await this.$store.dispatch('auth/logout');
          } catch (error) {
            console.log('Logout error');
          }

          this.$notify.info('Reason: try to register', 'You have been logged out!');
        }

        const user = await this.$store.dispatch('users/create', {
          type: 'student',
          ...values
        });
        global.ym(process.env.VUE_APP_YANDEX_METRIKA_ID, 'setUserID', user?.id);

        if (values.referral) delete values.referral;
        await this.$store.dispatch('auth/authenticate', {
          strategy: 'local',
          ...values
        });
        global.ym(process.env.VUE_APP_YANDEX_METRIKA_ID, 'reachGoal', 'create_account_success');

        const utm = localStorage.getItem('registerUtm');
        if (utm) {
          try {
            await this.$store.dispatch('utm/create', {
              ...JSON.parse(utm),
              userId: user.id
            });
          } catch (e) {
            console.error('UTM obj parse error', e);
          }

          localStorage.removeItem('registerUtm');
        }

        /*
          No need to set button awaiting animation to false after request is successful
          because we want to avoid flickering during router update
        */
        this.$router.push({ name: 'verify' });
      } catch (err) {
        this.awaitingRegisterResponse = false;
        const errorMessages = this.$notify.parseFeathersErrors(err);

        if (errorMessages && errorMessages.includes('email must be unique')) {
          this.$router.push({ name: 'login', query: { alreadyExistEmail: this.values.email } });
        }
      }
    }
  }
};
</script>

<style scoped>
.wrapper {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.form {
  width: 100%;
}

.link-question-icon {
  color: #258ffb;
  margin-left: 4px;
}
</style>
