<template>
  <div class="copyright">
    <slot></slot>
    <br>
    <router-link to="/terms">{{$t('Copyright.terms_conditions')}}</router-link> and
    <router-link to="/privacy">{{$t('Copyright.privacy_policy')}}</router-link></div>
</template>

<script>
  export default {
    name: 'Copyright'
  }
</script>

<style scoped>
  .copyright {
    font-size: 14px;
    text-align: center;
    color: #7D7D7D;
    padding-top: 8px;
    padding-bottom: 16px;
  }
</style>
