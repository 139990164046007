/* eslint-disable */
/*

позволяет использовать v-model для state из vuex
выполняет изменение значения через мутацию

example:
      ...mapFields({
        message: "message"
      }, "module"),
      ...mapFields({
        message: "module.message.str"
      }),
*/

import Vue from 'vue';

export default function mapFields(fields, storeKey) {
  const computed = {};
  for (const key in fields) {
    const path = fields[key].split('.');
    if (storeKey) path.unshift(storeKey);

    // console.log('map ' + key, path);

    computed[key] = {
      get() {
        let ptr = this.$store.state;
        for (let i = 0; i < path.length; i++) {
          if (!ptr[path[i]]) {
            return undefined;
          }
          ptr = ptr[path[i]];
        }
        return ptr;
      },
      set(value) {
        const moduleName = 'mapFieldsModule';
        if (!this.$store.hasModule(moduleName)) {
          console.log('register mapFieldsModule');
          this.$store.registerModule(moduleName, {
            mutations: {
              updateField(state, { path, value }) {
                console.log('update field req for state:', state, { path, value });
                let ptr = this.state;
                for (let i = 0; i < path.length; ++i) {
                  if (i < path.length - 1) {
                    if (!ptr[path[i]]) {
                      delete ptr[path[i]]; // fixed reactive problems (test)
                      // ptr[path[i]] = {};
                      Vue.set(ptr, path[i], {});
                      console.log('build chain ', path[i], path);
                    }
                    ptr = ptr[path[i]];
                  } else {
                    delete ptr[path[i]]; // fixed reactive problems
                    // ptr[path[i]] = value;
                    Vue.set(ptr, path[i], value);
                    console.log('set value ', path[i], value, path);
                  }
                }
              },
            },
            // namespaced:false, // по умолчанию и так false
          });
        }

        this.$store.commit('updateField', { path, value });
      },
    };
  }
  return computed;
}
