<template>
  <a-form-model-item ref="div" v-bind="{ ...$attrs, ...$props }" v-on="$listeners">
    <slot></slot>
  </a-form-model-item>
</template>

<script>
export default {
  name: 'UiFormItem'
};
</script>

<style lang="scss">
.ant-form-item-label {
  padding: 0px;
  margin-bottom: 2px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  white-space: normal !important;
}
</style>
