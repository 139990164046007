<template>
  <a-input-password
    size="large"
    class="ui-input-password"
    v-bind="{ ...$attrs, ...$props }"
    v-on="listeners"
  />
</template>

<script>
  import fixListeners from '@/utils/fixListeners';

  export default {
    name: 'UiPasswordInput',
    inheritAttrs: false,
    computed: {
      ...fixListeners()
    }
  }
</script>

<style lang="scss">
</style>
